import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Switcher from '../components/switcher'
import { Link } from 'react-router-dom'
import {MdOutlineArrowForward} from "../assets/icons/vander"

export default function WhyKBS() {
    return (
        <>
            <section className="relative md:py-24 py-16">
    <div className="container">
        <div className="md:flex flex-wrap p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md" style={{border:'0.5px solid #fab81c'}}>
            <div className="md:w-1/2">
                <div className="p-6 bg-white dark:bg-slate-900 ">
                    <h2 className="font-semibold mb-4" style={{fontSize:'40px'}}>Why Join KBS Academy</h2>
                    <h5 className="text-xl font-semibold mb-4 mt-8">Student Mentoring System & Motivation Classes</h5>
                    <p className="text-slate-400">At KBS we have implemented the campus management system, which helps to track the students record and instantly parents get the performance attendances in SMS alerts</p>
                    <h5 className="text-xl font-semibold mb-4 mt-8">Committed & Experienced Faculties</h5>
                    <p className="text-slate-400">Our teachers are passionate about teaching and understanding individual needs. At KBS Academy we have our own teaching skills, high standards and a unique technique for medical & engineering, by striving for ultimate excellence. Teaching method followed is highly innovative and robust. They try to understand the psychology of the students and guide them accordingly. We care for the student's success.</p>
                </div>
            </div>
            <div className="md:w-1/2">
                <div className="p-6 bg-white dark:bg-slate-900" style={{marginTop:'55px'}}>
                    <h5 className="text-xl font-semibold mb-4 mt-8">Past Experience in Educating and Tutoring</h5>
                    <p className="text-slate-400">KBS Academy has more than 13 years of experience in tutoring entrance aspirants and has a very healthy success rate. Mentoring plays an important role in career development. Prof Sreejith S, Academician & Chairman of KBS Academy brings in 13 years of teaching experience to train students for a successful future.</p>
                    <h5 className="text-xl font-semibold mb-4 mt-8">Economical Fee Structure and Easy Accessibility</h5>
                    <p className="text-slate-400">The nominal fee structure and easy accessibility of the institute makes it more affordable. The students are given individual guidance and parents are kept well informed about their performance. The centre offers complimentary night classes for all their regular students. All this with a fee structure so economical as to make the courses accessible to every one.</p>
                </div>
            </div>
        </div>
    </div>
</section>

        </>
    )
}
