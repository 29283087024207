import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import image from '../../../assets/images/items/3.gif';
import image1 from '../../../assets/images/avatar/1.jpg';
import image3 from '../../../assets/images/items/2.gif';
import image4 from '../../../assets/images/items/1.jpg';
import image5 from '../../../assets/images/items/2.jpg';
import image6 from '../../../assets/images/items/1.gif';
import HomeKBS from '../../../assets/images/onlinetest.webp';
import Navbar from '../../../components/navbar'
import Footer from '../../../components/footer'
import Switcher from '../../../components/switcher';
import { data } from '../../../data/data';
import {IoMdClose,BsExclamationOctagon, MdOutlineArrowForward} from "../../../assets/icons/vander"
// import image7 from '../../../assets/images/e-learning.jpg';
export default function KbsManagement() {
    const image7 = 'https://media.istockphoto.com/id/1366428092/photo/webinar-e-learning-skills-business-internet-technology-concepts-training-webinar-e-learning.jpg?s=612x612&w=0&k=20&c=mJeYiZyGQXLXXZ6AWKbLwQDiuLr-YLRGV4VjHKdX0pM='
    const params = useParams();
    const id = params.id
    const creater = data.find((creatorr) => creatorr.id === parseInt (id));

    const [activeIndex, setIndex] = useState(0);
    const [placeBid , setPlaceBid] = useState(false)
    const [ buyNow, setBuyNow] =  useState(false)

    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    return (
        <>
            <Navbar />
            {/* <nav className="custom-nav" style={{marginTop:"150px"}}>
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
            <section className="relative table w-full py-366 bg-cover" style={{ backgroundImage: `url(${HomeKBS})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                <div className="grid grid-cols-1 text-center" style={{backdropFilter: 'blur(8px)',borderRadius:'6px'}}>
                    <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{color:'#fff'}}><span>Register for Online Test series</span></h4>
                </div>

                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
              <section className='mt-24'>
                 <div className='container p-6'>
                     <div className="bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-[30px] p-6">
                     <div className="container">
                     <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
                         <div className="lg:col-span-5">
                             <img src={image7} className="rounded-md shadow dark:shadow-gray-700 w-full h-auto" alt="" />
                             
                         </div>
 
                         <div className="lg:col-span-7 lg:ms-8">
                             <h5 className="md:text-2xl text-xl font-semibold">{creater?.title ? creater?.title :"Start your own online education"}</h5>
 
                             <p className="text-slate-400 mt-4">In reality to start your own online education, lot of time effort and money is required which does not seem all that possible and for most of the people it remains a dream.</p>
                             <br />
                             <h5 className="md:text-2xl text-xl ">{creater?.title ? creater?.title :"Call Our Helpdesk"}</h5>
                             <p className="text-slate-400 mt-4">79026 20001</p>
                         </div>
                     </div>
                 </div>
                     </div>
                 </div> 
             </section>
            <Footer />
            <Switcher />
        </>
    )
}
