import React from 'react'
import contact from '../../../assets/images/contact.svg';
import { Link } from 'react-router-dom';
import {FaRegEnvelope, BsTelephone, FiMapPin, GoLocation, MdEmail, LiaFacebookF, AiOutlineInstagram, AiOutlineTwitter} from "../../../assets/icons/vander"
import { PhoneCall } from 'feather-icons-react/build/IconComponents';
import Footer from '../../../components/footer';
import Navbar from '../../../components/navbar';
import Switcher from '../../../components/switcher';
import HomeKBS from '../../../../src/assets/images/bg/bg3.png';

export default function ContactUs() {
    return (
        <>
            <Navbar />
            {/* <nav className="custom-nav" >
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
            <section className="relative table w-full py-366 bg-cover" style={{ backgroundImage: `url(${HomeKBS})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                <div className="grid grid-cols-1 text-center" style={{backdropFilter: 'blur(8px)',borderRadius:'6px'}}>
                    
                    <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{color:'#fff'}}><span>Contact Us</span></h4>
                </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

                                                    
            <div className="container md:mt-24 mt-16">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-8 md:col-span-6 order-1 md:order-2">
                            <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                                <h3 className="mb-6 text-2xl leading-snug font-medium">Feedback Form</h3>
                                <form method="post" name="myForm" id="myForm">
                                    <p className="mb-0" id="error-msg"></p>
                                    <div id="simple-msg"></div>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <div className="text-start">
                                                <label htmlFor="name" className="font-semibold">Your Name:</label>
                                                <input id="name" type="text" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Name :" />
                                            </div>
                                        </div>
                                        <div className="lg:col-span-6 mb-5">
                                            <div className="text-start">
                                                <label htmlFor="email" className="font-semibold">Your Email:</label>
                                                <input id="email" type="email" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Email :" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <div className="text-start">
                                                <label htmlFor="number" className="font-semibold">Your Phone No:</label>
                                                <input id="number" type="number" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Phone no :" />
                                            </div>
                                        </div>
                                        <div className="mb-5">
                                            <div className="text-start">
                                                <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                <textarea id="comments" className="form-input w-full text-[15px] py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-2xl outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Message :"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send" className="btn bg-violet-600 hover:bg-violet-600 border-violet-600 hover:border-violet-600 text-white rounded-full justify-center flex items-center">Submit</button>
                                </form>
                            </div>
                        </div>

                        <div className="lg:col-span-4 md:col-span-6 mt-8 md:mt-0 order-2 md:order-1">
                            <div className="lg:me-5">
                                <div className="bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 p-6" style={{height:'500px',justifyContent:'center',display:'flex'}}>
                                    <div className="lg:col-span-3 md:col-span-12" style={{marginTop:'50px',marginLeft:'15px'}}>
                                        <h5 className="tracking-[1px] text-lg text-white font-bold">CONTACT INFO</h5>
                                        <ul className="list-none footer-list mt-6">
                                            <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex"  style={{color:'black'}}><GoLocation className="me-2 text-lg"/> Near Ayyappankavu Junction, </Link></li>
                                            <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex"  style={{color:'black'}}> Near Ayyappankavu Junction, Pirayiri Post, Palakkad,</Link></li>
                                            <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex"  style={{color:'black'}}>PIN:678004 Kerala, India</Link></li>
                                            <li className="mt-[10px]"><Link to="mailto:sreeju.821@gmail.com" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"  style={{color:'white'}}><MdEmail className="me-3 text-lg"/> sreeju.821@gmail.com</Link></li>
                                            <li className="mt-[10px]"><Link to="tel:+91 9995484636" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"  style={{color:'white'}}><PhoneCall className="me-2 text-lg"/> +91 9995484636</Link></li>
                                        </ul>
                                        <ul className="list-none md:text-start mt-5">
                                            <li className="inline space-x-1"><Link   className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 "><LiaFacebookF  style={{color:'white'}} className='text-base'/></Link></li>
                                            <li className="inline space-x-1"><Link   className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 "><AiOutlineInstagram  style={{color:'white'}} className='text-base'/></Link></li>
                                            <li className="inline space-x-1"><Link   className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 "><AiOutlineTwitter  style={{color:'white'}} className='text-base'/></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            <section className="relative md:py-24 py-16">

                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-8 md:col-span-6 order-1 md:order-2">
                            <div className="container-fluid relative">
                                <div className="grid grid-cols-1">
                                    <div className="w-full leading-[0] border-0">
                                        <iframe title="iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d250836.41218916932!2d76.37322469633914!3d10.786866219520117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba86e084d00b6b5%3A0xf74e6bc864017d8e!2sKBS%20Academy%20Palakkad%20Town%20Campus!5e0!3m2!1sen!2sin!4v1710735428272!5m2!1sen!2sin" style={{ border: 0,height:'300px' }} className="w-full" allowFullScreen></iframe>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className="lg:col-span-4 md:col-span-6 mt-8 md:mt-0 order-2 md:order-1">
                            <div className="lg:me-5 bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 w-full" style={{height:'300px',display:'flex',justifyContent:'center',alignContent:'center'}}>
                                <img src={contact} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                
            </section>

            <Footer />
            <Switcher />
        </>
    )
}
