import React, { useEffect } from 'react'
import metamask from '../../../assets/images/wallet/metamask.png';
import alpha from '../../../assets/images/wallet/alpha.png';
import binance from '../../../assets/images/wallet/binance.png';
import bitski from '../../../assets/images/wallet/bitski.png';
import coinbase from '../../../assets/images/wallet/coinbase.png';
import enjin from '../../../assets/images/wallet/enjin.png';
import formatic from '../../../assets/images/wallet/formatic.png';
import pionex from '../../../assets/images/wallet/pionex.png';
import torus from '../../../assets/images/wallet/torus.png';
import trustwallet from '../../../assets/images/wallet/trustwallet.png';
import connect from '../../../assets/images/wallet/wallet-connect.png';
import Navbar from '../../../components/navbar'
import Footer from '../../../components/footer';
import Switcher from '../../../components/switcher';
import { Link } from 'react-router-dom';
import {MdOutlineArrowForward} from "../../../assets/icons/vander"
import HomeKBS from '../../../assets/images/enquiry.jpg';

export default function ExamEnquiry() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    const walletData = [
        {
            title: 'AIPMT ENTRANCE EXAM',
            p: "In compliance with the directives of the Hon’ble Supreme Court of India, the Central Board of Secondary Education.",
        },
        {
            title: 'AIIMS ENTRANCE EXAM',
            p: 'The dreams of Jawaharlal Nehru and Amrit Kaur and the recommendations of the Bhore Committee converged to'
        },
        {
            title: 'VMMC ENTRANCE EXAM',
            p: 'Guru Gobind Singh Indraprastha University (GGSIPU) is established by the Government of National Capital'
        },
        {
            title: 'MANIPAL MEDICAL ENTRANCE EXAM',
            p: 'MU has 286 courses across 15 professional streams. On last 60 years of experience, over 28000 students'
        },
        {
            title: 'CMC VELLORE MEDICAL EXAM',
            p: 'The Undergraduate course in Medicine at CMC-Vellore comprises of three stages. The first stage of 12 months'
        },
        {
            title: 'AMU MEDICAL EXAM',
            p: 'Aligarh Muslim University was established by Syed Ahmed Khan in the year 1875. This Mohammedan Anglo'
        },
        {
            title: 'OLYMPIADS',
            p: 'The Olympiad programmes include Mathematics, Physics, Chemistry, Biology and Junior Science'
        },
        {
            title: 'NEET',
            p: 'NEET (National Eligibility Cum Entrance Test -UG) is an All India entrance exam conducted by CBSE'
        },
        {
            title: 'JEE (MAIN) ENTRANCE EXAM',
            p: 'The 4th Joint Entrance Examination (Main) – 2018 will be conducted by the JEE Apex Board (JAB) for admission'
        },
        {
            title: 'JEE(ADVANCED) ENTRANCE EXAM',
            p: 'The JEE (Advanced)- will be conducted by Seven Zonal IITs (under the directives of the Joint Admission Board)'
        },
        {
            title: 'VITEEE ENTRANCE EXAM',
            p: 'VIT University, established under Section 3 of the University Grants Commission (UGC) Act, 1956,'
        },
        {
            title: 'MANIPAL ENGG. EXAM',
            p: 'MU has 286 courses across 15 professional streams. On last 60 years of experience,'
        },
        {
            title: 'AMU ENGG. ENTRANCE EXAM',
            p: 'Lord Lytton laid the foundation stone of the Mohammedan Anglo Oriental College on 8 January, 1877'
        },
        {
            title: 'BITSAT ENGG. EXAM',
            p: 'The Birla Institute of Technology and Science (BITS) Pilani is an all India Institution declared as Deemed'
        },
        {
            title: 'KEAM',
            p: 'The office of the Commissioner for Entrance Exams (CEE) conducts Kerala Engineering, Architecture'
        },
        {
            title: 'CUSAT',
            p: 'CUSAT CAT is an all India level common admission test conducted by the Cochin University of Science'
        },
        {
            title: 'COMEDK UGET',
            p: 'Consortium of Medical Engineering and Dental Colleges of Karnataka (COMED K) conducts a state-level'
        },
        {
            title: 'SRMJEE',
            p: 'SRM University also conducts SRM Joint Engineering Entrance Exam (SRMJEEE) through which it offers'
        },
        {
            title: 'MEDICAL ENTRANCE EXAMS',
            p: 'content will be updated soon'
        },
        {
            title: 'FOUNDATION LEVEL EXAMS',
            p: 'Content will Update Soon'
        },
        {
            title: 'ENGINEERING ENTRANCE EXAMS',
            p: 'Content will be Updated Soon'
        },
        {
            title: 'JUNIOR SCIENCE TALENT SEARCH EXAM (JSTSE)',
            p: 'Junior Science Talent Search Examination (JSTSE) is conducted by Science Branch of Directorate of Education,'
        },
        {
            title: 'NATIONAL MEANS-CUM-MERIT SCHOLARSHIP (NMMS) EXAM',
            p: 'It is organized by Ministry of Human Resource and Development, Government of India.'
        },
        {
            title: 'KISHORE VAIGYANIK PROTSAHAN YOJANA (KVPY) EXAM',
            p: 'Tentative Month of Notification July-August Tentative Month of Level-1 Exam October Name of the Entrance Exams'
        },
        {
            title: 'NATIONAL TALENT SEARCH EXAMINATION (NTSE)',
            p: 'National Talent Search Examination (NTSE) is a national-level scholarship program. The examination'
        },
        {
            title: 'FOUNDATION LEVEL EXAMS: CALENDAR',
            p: 'Content will Update Soon'
        },
              
    ]
    return (
        <>
            <Navbar />
            {/* <nav className="custom-nav" >
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
            {/* <nav className="custom-nav">
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
            <section className="relative table w-full py-366 object-cover" style={{ backgroundImage: `url(${HomeKBS})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center" style={{ backdropFilter: 'blur(8px)', borderRadius: '6px' }}>
                        <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{ color: '#fff' }}><span>Exam Enquiry</span></h4>
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]" style={{display:'flex',justifyContent:'stretch',alignContent:'stretch'}}>
                        {
                            walletData.map((ele, index) => (
                                <div key={index} className="group relative rounded-xl bg-white dark:bg-slate-900 shadow1 dark:shadow-gray-800 p-6 text-center mt-10 hover:shadow-md dark:hover:shadow-gray-800 transition-all duration-500">
                                    <div className="relative">
                                        <h5 className="text-lg font-semibold mt-5">{ele.title}</h5>
                                        <p className="text-slate-400 mt-3">{ele.p}</p>
                                        <div style={{display:'flex',justifyContent:'center',bottom:0}}>
                                            <Link to="#" className="btn bg-transparent hover:bg-violet-600 border-violet-600 text-violet-600 hover:text-white ms-2 mt-8" style={{cursor:'pointer',borderRadius:'7px'}}>Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
