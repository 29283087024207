import {React, useState} from 'react'
import Footer from '../../../components/footer'

import contact from '../../../assets/images/contact.svg';
import Switcher from '../../../components/switcher';
import { Link } from 'react-router-dom';
import {FaRegEnvelope, BsTelephone, FiMapPin} from "../../../assets/icons/vander"
import ModalVideo from 'react-modal-video';
import { studentFeedbacks } from '../../../data/data';
import Navbar from '../../../components/navbar';
import HomeKBS from '../../../../src/assets/images/bg/bg2.png';



export default function StudentsFeedback() {
    const [videoStates, setVideoStates] = useState(studentFeedbacks.map(() => false));

    const handleVideoClick = (index) => {
        const newVideoStates = [...videoStates];
        newVideoStates[index] = !newVideoStates[index];
        setVideoStates(newVideoStates);
    };
    return (
        <>
            <Navbar />
            {/* <nav className="custom-nav">
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
            <section className="relative table w-full py-366 bg-cover" style={{ backgroundImage: `url(${HomeKBS})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                <div className="grid grid-cols-1 text-center" style={{backdropFilter: 'blur(8px)',borderRadius:'6px'}}>  
                    <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{color:'#fff'}}><span>Students Feedback </span></h4>
                </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section>
                <div className='container p-6'>
                    <div className="bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-[30px] p-6">
                        <div> {/* <span className="font-medium text-slate-400 block mb-1">Token ID</span> */}
                            <span className="font-medium block">What our students & Parents tell regarding KBS Academy, the pioneer Entrance coaching center at Palakkad,Kerala. This makes KBS Academy one among the top 10 entrance coaching centers in Kerala.</span>
                        </div>
                    </div>
                </div> 
            </section>
            
            <section className="relative">
                <div className="container" style={{display:'flex',justifyContent:'center',}}>
                    <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 mt-8 gap-[30px]"
                    >
                        {studentFeedbacks.map((student, index) => (
                            <div key={index}>
                                <div className="relative iframe-youtube1" style={{background:'#000'}}>
                                    <iframe className='iframe-youtube1' src={`https://www.youtube.com/embed/${student.videoLink}?si=T7LbMebx6ftK9dmN`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>
            <Footer />
            <Switcher />
        </>
    )
}