import React from 'react'
import Navbar from '../../../components/navbar'
import Footer from '../../../components/footer';
import Switcher from '../../../components/switcher';
import TutuionEntrance from '../../../components/tution-entrance';
import ScrollToTop from '../../../components/scroll-top';
import Vacation from '../../../components/vacation';

export default function TuitionEntrance() {

    return (
        <>
            <Navbar />
            {/* <nav className="custom-nav" style={{marginTop:"120px"}}>
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
            <section className="relative md:pb-24 pb-16" style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:'30px',marginBottom:'-150px'}}>
               <TutuionEntrance title="Tuition + Entrance" />
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
