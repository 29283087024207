import React, { useEffect } from 'react'
import image from '../../../assets/images/items/1.gif';
import image1 from '../../../assets/images/items/1.jpg';
import image2 from '../../../assets/images/items/2.jpg';
import image3 from '../../../assets/images/items/3.jpg';
import image4 from '../../../assets/images/items/2.gif';
import image5 from '../../../assets/images/items/4.jpg';
import image6 from '../../../assets/images/items/3.gif';
import Navbar from '../../../components/navbar'
import Footer from '../../../components/footer'
import Switcher from '../../../components/switcher';
import { Link } from 'react-router-dom';
import HomeKBS from '../../../../src/assets/images/fee-payment.jpg';
import {LuSearch, PiWalletBold, BsTag, PiFireSimpleBold, FiHeart, PiBrowsersLight,FiUsers,VscListFlat, PiMusicNotesBold, LuCamera,FaRegPaperPlane} from "../../../assets/icons/vander"

export default function Activity() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    return (
        <>
            <Navbar />
            {/* <nav className="custom-nav" style={{marginTop:"150px"}}>
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
            <section className="relative table w-full py-366 bg-cover" style={{ backgroundImage: `url(${HomeKBS})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center" style={{backdropFilter: 'blur(8px)',borderRadius:'6px'}}>  
                     <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{color:'#fff'}}><span>Fee Payment</span></h4>
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16" style={{padding:'24px'}}>
                <div className="container" style={{border:'2px solid #fab81c',borderRadius:'6px',padding:'14px'}}>
                    <div className="md:flex">
                        <div className="lg:w-2.5/5 md:w-1/2">
                            <div className="p-6 bg-white dark:bg-slate-900" style={{display:'flex',justifyContent:'center'}}>
                                <h2 className="font-semibold feePayments">NEFT Transfer</h2>
                            </div>
                        </div>

                        <div className="lg:w-2.5/5 md:w-1/2">
                        <div className="p-6 bg-white dark:bg-slate-900">
                          <ul className="list-none text-slate-400">
                              <li className="flex items-center">Current Ac Name: KBS ACADEMY</li>
                              <br />
                              <li className="flex items-center mt-2">Ac Number: 50200028047823</li>
                              <br />
                              <li className="flex items-center mt-2">Bank Name: HDFC bank</li>
                              <br />
                              <li className="flex items-center mt-2">IFSC Code: HDFC0004851</li>
                              <br />
                              <li className="flex items-center mt-2">Branch Name: Olavakkod</li>
                         </ul>
                     </div>
                        </div>
                    </div>
                    <hr style={{border:'0.1px solid #e6e6e6',marginTop:'15px'}}/>
                    <div className="md:flex">
                        <div className="lg:w-2.5/5 md:w-1/2">
                            <div className="p-6 bg-white dark:bg-slate-900" style={{display:'flex',justifyContent:'center'}}>
                            <h2 className="font-semibold" style={{fontSize:'19px',display:'flex',justifyContent:'center',marginTop:'10px'}}>Google Pay or Gpay number</h2>
                            </div>
                        </div>

                        <div className="lg:w-2.5/5 md:w-1/2">
                        <div className="p-6 bg-white dark:bg-slate-900">
                          <ul className="list-none text-slate-400 mt-2">
                          <li className="flex items-center">9995484636</li>
                         </ul>
                     </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <Switcher />
        </>
    )
}

