import React, { useEffect, useState } from 'react';
import image from '../../../assets/images/items/2.jpg';
import single from '../../../assets/images/faculty1.jpg';
import image1 from '../../../assets/images/blog/05.jpg';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Switcher from '../../../components/switcher';
import { Link, useParams } from 'react-router-dom';
import { LuClock, LuCalendarDays } from '../../../assets/icons/vander';
import { blogData } from '../../../data/data';

export default function OurFaculty() {
  const params = useParams();
  const id = params.id;
  const blog = blogData.find((blogs) => blogs.id === parseInt(id));

  const [counters, setCounters] = useState([
    {
      id: 1,
      target: 13,
      current: 0,
      label: 'Nobel laureates',
      sub: "bachelor's recipients were employed",
    },
    {
      id: 2,
      target: 12,
      current: 0,
      label: 'MacArthur fellows',
      sub: "bachelor's recipients were employed",
    },
    {
      id: 3,
      target: 113,
      current: 0,
      label: 'NCAA titles',
      sub: "bachelor's recipients were employed",
    },
    {
      id: 4,
      target: 250,
      current: 0,
      label: 'Olympic medals',
      sub: "bachelor's recipients were employed",
    },
  ]);

  useEffect(() => {
    const updateCounter = (index) => {
      setCounters((prevCounters) => {
        const newCounters = [...prevCounters];
        const increment = newCounters[index].target / 2000000000;
        if (newCounters[index].current < newCounters[index].target) {
          newCounters[index].current = Math.ceil(
            newCounters[index].current + increment
          );
          setTimeout(() => updateCounter(index), 1);
        } else {
          newCounters[index].current = newCounters[index].target;
        }
        return newCounters;
      });
    };

    counters.forEach((_, index) => updateCounter(index));
  }, []);

  return (
    <>
      <Navbar />
      {/* <nav className="custom-nav">
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
      <section className="relative pt-32 md:pb-24 pb-16">
        <div className="container">
          <div className="md:flex justify-center"></div>

          <div className="md:flex justify-center mt-6">
            <div className="lg:w-full">
              <div className="relative">
                <img
                  style={{ justifyContent: 'center' }}
                  src={blog?.image ? blog?.image : single}
                  className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto"
                  alt=""
                />
              </div>
              <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                {counters.map((counter, index) => (
                  <div key={index} className="counter-container">
                    {counter.id === 4 && (
                      <div className="counter">{counter.current}+</div>
                    )}
                    {counter.id !== 4 && (
                      <div className="counter">{counter.current}</div>
                    )}
                    <span style={{ marginTop: '20px', fontSize: '20px', fontFamily: 'bold' }}>
                      {counter.label}
                    </span>
                    <span className="text-slate-400" style={{ marginTop: '5px' }}>
                      {counter.sub}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Switcher />
    </>
  );
}