import React from 'react'
import Navbar from '../../../components/navbar'
import Footer from '../../../components/footer';
import Switcher from '../../../components/switcher';
import TutuionEntrance from '../../../components/tution-entrance';
import Entrance from '../../../components/entrance';
import Tuition from '../../../components/tution';
import Vacation from '../../../components/vacation';
import { Link } from 'react-router-dom';
import { entrance } from '../../../data/data';

export default function Jee() {

    return (
        <>
            <Navbar />
            {/* <nav className="custom-nav" style={{marginTop:"50px"}}>
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
            <section className="relative"  style={{marginTop:'100px'}}>
            <div className="container">
                <h2 to="#" className="font-semibold transition duration-500" style={{fontSize:'30px',marginBottom:'35px',}}>JEE</h2>
                    <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        {
                            entrance.map((ele, index) => (
                                <div key={index} className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-500">
                                    <div style={{maxWidth:'100%',height:'150px',backgroundColor:'#1e293b'}}>
                                        <div className="relative p-6" style={{display:'flex',alignItems:'center',justifyContent:'center',textAlign:'center'}}>
                                            <Link to={`#`} className="title text-lg font-medium hover:text-violet-600 duration-500 ease-in-out mt-8">{ele.title1}</Link>                             
                                        </div>
                                    </div>

                                    <div className="relative p-6">

                                        <div className="">
                                            <div className="flex mb-4" style={{display:'flex',flexDirection:'column'}}>
                                                <span className="text-slate-400 text-[16px] inline-flex mb-4">{ele.q2}</span>
                                                {/* <span className="text-slate-400 text-[16px] inline-flex mb-4"><CheckCircle className="text-slate-900 dark:text-white me-2"/>Duration : {ele.p2}</span>
                                                <span className="text-slate-400 text-[16px] inline-flex mb-4"><CheckCircle className="text-slate-900 dark:text-white me-2"/>Admission Time : {ele.p5}</span>
                                                <span className="text-slate-400 text-[16px] inline-flex mb-4"><CheckCircle className="text-slate-900 dark:text-white me-2"/>Admission Criterion : {ele.p3}</span>
                                                <span className="text-slate-400 text-[16px] inline-flex mb-4"><CheckCircle className="text-slate-900 dark:text-white me-2"/>Class starts on : {ele.p4}</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4" style={{display:'flex',justifyContent:'center',bottom:0}}>
                                    <Link to="#" className="btn bg-transparent hover:bg-violet-600 border-violet-600 text-violet-600 hover:text-white rounded-full ms-2" style={{cursor:'pointer',}}>Download</Link>
                                </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>

            <Footer />
            <Switcher />
        </>
    )
}
