import React, { useState } from 'react';

const CustomMenu = () => {

  return (
    <nav className="custom-nav">
      <ul>
        <li className="facebook">
          <a href="#">
            <i className="bi--facebook"></i>
            <span>Facebook</span>
          </a>
        </li>
        <li className="twitter">
          <a href="#">
            <i className="fab fa-twitter"></i>
            <span>Twitter</span>
          </a>
        </li>
        <li className="instagram">
          <a href="#">
            <i className="fab fa-instagram"></i>
            <span>Instagram</span>
          </a>
        </li>
        <li className="linkedin">
          <a href="#">
            <i className="fab fa-linkedin-in"></i>
            <span>Linkedin</span>
          </a>
        </li>
        <li className="github">
          <a href="#">
            <i className="fab fa-github"></i>
            <span>Github</span>
          </a>
        </li>
        <li className="youtube">
          <a href="#">
            <i className="fab fa-youtube"></i>
            <span>Youtube</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default CustomMenu;
