import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import image from '../../../assets/images/items/3.gif';
import image1 from '../../../assets/images/avatar/1.jpg';
import image3 from '../../../assets/images/items/2.gif';
import image4 from '../../../assets/images/items/1.jpg';
import image5 from '../../../assets/images/items/2.jpg';
import image6 from '../../../assets/images/items/1.gif';
import HomeKBS from '../../../assets/images/background-management.jpg';
import Navbar from '../../../components/navbar'
import Footer from '../../../components/footer'
import Switcher from '../../../components/switcher';
import { data } from '../../../data/data';
import {IoMdClose,BsExclamationOctagon, MdOutlineArrowForward} from "../../../assets/icons/vander"
import image7 from '../../../assets/images/kbs1.png';
export default function KbsManagement() {

    const params = useParams();
    const id = params.id
    const creater = data.find((creatorr) => creatorr.id === parseInt (id));

    const [activeIndex, setIndex] = useState(0);
    const [placeBid , setPlaceBid] = useState(false)
    const [ buyNow, setBuyNow] =  useState(false)

    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    return (
        <>
            <Navbar />
            {/* <nav className="custom-nav">
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
            <section className="relative table w-full py-366 object-cover" style={{ backgroundImage: `url(${HomeKBS})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                <div className="grid grid-cols-1 text-center" style={{backdropFilter: 'blur(8px)',borderRadius:'6px'}}>
                    <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{color:'#fff'}}><span>KBS Management</span></h4>
                </div>

                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative pt-28 md:pb-24 pb-16">
                <div className="container">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-5">
                            <img src={image7} className="rounded-md shadow dark:shadow-gray-700" style={{width:'372px',height:'372px'}} alt="" />
                            {/* <img src={creater?.image ? creater?.image : image} className="rounded-md shadow dark:shadow-gray-700" style={{width:'372px',height:'372px'}} alt="" /> */}

                            {/* <div className="bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-[30px] p-6">
                                <div>
                                    <span className="font-medium text-slate-400 block mb-1">Contract Address</span>
                                    <Link to="#" className="font-medium text-violet-600 underline block">1fsvtgju51ntgeryimghf6ty7o9n3r3er246</Link>
                                </div>

                                <div className="mt-4">
                                    <span className="font-medium text-slate-400 block mb-1">Token ID</span>
                                    <span className="font-medium block">458342529342930944</span>
                                </div>

                                <div className="mt-4">
                                    <span className="font-medium text-slate-400 block mb-1">Blockchain</span>
                                    <span className="font-medium block">ETH</span>
                                </div>

                                <div className="mt-4">
                                    <span className="font-medium text-slate-400 block mb-1">Deposit & Withdraw</span>
                                    <span className="font-medium block">Unsupported</span>
                                </div>
                            </div> */}
                        </div>

                        <div className="lg:col-span-7 lg:ms-8">
                            <h5 className="md:text-2xl text-xl font-semibold">{creater?.title ? creater?.title :"KBS Academy – Tuitions & Entrance Coaching Centre"}</h5>

                            {/* <span className="font-medium text-slate-400 block mt-2">From this collection: <Link to="/creator-profile" className="text-violet-600">{creater?.subtext ? creater?.subtext : "@FunnyGuy"}</Link></span> */}

                            <p className="text-slate-400 mt-4">KBS Academy is an learning centre which render coaching for major entrance examinations like NEET/JEE/KEAM.NATA,ICAR etc. It provide tuitions for classes 8th to 12th.  We offer courses focusing Tuition Only, Entrance Only, Tuition + Entrance, Entrance + Schooling, Bridge Courses & Foundation Courses.</p>
                            <p className="text-slate-400 mt-4">It was started with an objective of providing excellent science education for Plus I and Plus II students of Palakkad. The aim of the academy is to become an outstanding educational institution in the district. Now institute has hostel facility and it is accommodating students from outside Kerala also.</p>
                            <p className="text-slate-400 mt-4">Over the years it has helped hundreds of students to pass their examinations with flying colors. KBS is the only institution in the district providing value-based education and empowering students to pursue higher education with a mission and vision. In short, KBS is all set to ensure a glorious future for the young generation.</p>
                            {/* <h5 className="text-xl font-semibold mb-4 mt-8">Restrictions :</h5>
                                <p className="text-slate-400">You are specifically restricted from all of the following :</p> */}
                                <ul className="list-none text-slate-400 mt-3">
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/> Easily accessible learning center in Palakkad</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Classes for Plus I & Plus II Science students</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Term-wise mark lists & attendance reports will be sent to the parents</li>
                                </ul>

                            {/* <div className="mt-4">
                                <span className="text-lg font-medium text-slate-400 block">Market Price</span>
                                <span className="tmd:text-2xl text-xl font-semibold block mt-2"><i className="mdi mdi-ethereum"></i> 3.5 ETH = $ 4,659.75</span>
                            </div> */}

                            {/* <div className="mt-6">
                                <Link to="#" onClick={()=> setPlaceBid(!placeBid)} className="btn rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"><i className="mdi mdi-gavel"></i> Bid Now</Link>
                                <Link to="#" onClick={() => setBuyNow(!buyNow)} className="btn rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white ms-1"><i className="mdi mdi-lightning-bolt"></i> Buy Now</Link>
                            </div> */}

                            {/* <div className="md:flex p-6 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700 mt-6">
                                <div className="md:w-1/2">
                                    <div className="flex items-center">
                                        <div className="relative inline-block">
                                            <img src={creater?.avatar ? creater?.avatar : image1} className="h-16 rounded-md" alt="" />
                                            <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                        </div>

                                        <div className="ms-3">
                                            <Link to="/creator-profile" className="font-semibold block hover:text-violet-600">{creater?.name ? creater?.name :"Michael Williams"}</Link>
                                            <span className="text-slate-400 text-[16px] block mt-1">Creator</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="md:w-1/2 md:mt-0 mt-4">
                                    <div className="flex items-center">
                                        <div className="relative inline-block">
                                            <img src={creater?.avatar ? creater?.avatar : image1} className="h-16 rounded-md" alt="" />
                                            <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                        </div>

                                        <div className="ms-3">
                                            <Link to="/creator-profile" className="font-semibold block hover:text-violet-600">{creater?.name ? creater?.name :"Michael Williams"}</Link>
                                            <span className="text-slate-400 text-[16px] block mt-1">Owner</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            
                        </div>
                    </div>
                </div>
                <section className='container' style={{display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                <div className="grid grid-cols-1 mt-8">
                                <ul className="flex-wrap justify-center text-center p-3 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md" id="myTab" data-tabs-toggle="#StarterContent" role="tablist">
                                    <li role="presentation" className="md:inline-block block md:w-fit">
                                        <button className={`px-6 py-2 font-semibold rounded-md  transition-all duration-500 ease-in-out ${activeIndex === 0 ? 'text-white bg-violet-600' : ''}`} id="thursday-tab" data-tabs-target="#thursday" type="button" style={{ width: activeIndex === 0 ? '250px' : 'auto' }}
                                            onClick={() => setIndex(0)}
                                            role="tab" aria-controls="thursday" aria-selected="false">Our Mission</button>
                                    </li>
                                    <li role="presentation" className="md:inline-block block md:w-fit ">
                                        <button className={`px-6 py-2 font-semibold rounded-md  transition-all duration-500 ease-in-out ${activeIndex === 1 ? 'text-white bg-violet-600' : ''}`} id="thursday-tab" data-tabs-target="#thursday" type="button" style={{ width: activeIndex === 1 ? '250px' : 'auto' }}
                                            onClick={() => setIndex(1)}
                                            role="tab" aria-controls="thursday" aria-selected="false">Our Vision</button>
                                    </li>
                                    <li role="presentation" className="md:inline-block block md:w-fit ">
                                        <button className={`px-6 py-2 font-semibold rounded-md transition-all duration-500 ease-in-out ${activeIndex === 2 ? 'text-white bg-violet-600' : ''}`} id="thursday-tab" data-tabs-target="#thursday" type="button" style={{ width: activeIndex === 2 ? '250px' : 'auto' }}
                                            onClick={() => setIndex(2)}
                                            role="tab" aria-controls="thursday" aria-selected="false">Features</button>
                                    </li>
                                </ul>

                                <div id="StarterContent" className="mt-6">
                                    <div className="" id="wednesday" role="tabpanel" aria-labelledby="wednesday-tab">
                                                {activeIndex === 0 && (
                                                <div className="group relative rounded-xl bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 p-6 text-center mt-10 cardWidth">
                                                    <div className="relative">
                                                        <p className="skill-count1">To be a natural or first choice of students as a center of help for education and to become a leader in competitive exam coaching by partnering with various stake holders students,parents,faculities,alumini,school authorities and local bodies.</p>
                                                    </div>
                                                </div>
                                            )}
                                            {activeIndex === 1 && (
                                            <div className="group relative rounded-xl bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 p-6 text-center mt-10 cardWidth">
                                                <div className="relative">
                                                    <p className="skill-count1">To be a learning center which produce or mold dynamic,disciplined & efficient aspirants by providing best services under single roof,being accessible to all socio-economic groups.</p>
                                                </div>
                                            </div>
                                            )}                                         
                                            {activeIndex === 2 && (
                                            <div className="group relative rounded-xl bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 p-6 text-center mt-10 cardWidth">
                                                <div className="relative">
                                                    <ul className="list-none text-slate-400 mt-3">
                                                        <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Excellent Academic Records </li>
                                                        <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Daily Practice Tests</li>
                                                        <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Parent app for student progress.</li>
                                                        <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Study Materials</li>
                                                    </ul>
                                                    <ul className="list-none text-slate-400 mt-6">
                                                        <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/> Experienced Faculity</li>
                                                        <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Online Test Series</li>
                                                        <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Daily doubt Clearing Sessions</li>
                                                    </ul>
                                                    <ul className="list-none text-slate-400 mt-6">
                                                        <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/> Complete Coverage of Syllabus</li>
                                                        <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>CCTV Monitoring</li>
                                                        <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Motivation Classes</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            )}                                         
                                    </div>
                                </div>
                            </div>
                </section>
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
