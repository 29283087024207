import React from 'react';
import HomeKBS from '../../../assets/images/importent-dates1.jpeg';
import Navbar from '../../../components/navbar';
import Switcher from '../../../components/switcher';
import Footer from '../../../components/footer';

export default function ImportantDates() {
  return (
    <>
      <Navbar />
      {/* <nav className="custom-nav" style={{marginTop:"150px"}}>
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
      <section className="relative table w-full py-366 bg-cover" style={{ backgroundImage: `url(${HomeKBS})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center" style={{ backdropFilter: 'blur(8px)', borderRadius: '6px' }}>
            <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{ color: '#fff' }}><span>Important Dates</span></h4>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
          <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
          </svg>
        </div>
      </div>

      <div className="w-full max-w-6xl mx-auto mt-24 p-6 ">
        <div className="flex justify-center">
          <div className="w-full max-w-2xl shadow-xl rounded-2xl rounded-xl p-6" style={{textAlign:'center'}}>
            <div className="p-3">
              <div className="overflow-x-auto">
                <table className="table-auto w-full border-collapse tablee">
                  <thead className="text-[13px] text-slate-500/70 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">
                    <tr>
                      <th className="px-5 py-2 first:pl-3 last:pr-3 bg-slate-100 first:rounded-l last:rounded-r last:pl-5 last:sticky last:right-0"  style={{border:"3px solid #fff"}}>
                        <div className="font-medium text-left" style={{fontSize:"23px"}}>Entrance Exam Name</div>
                      </th>
                      <th className=" px-5 py-2 first:pl-3 last:pr-3 bg-slate-100 first:rounded-l last:rounded-r last:pl-5 last:sticky last:right-0"  style={{border:"3px solid #fff"}}>
                        <div className="font-medium text-left"  style={{fontSize:"23px"}}>Date</div>
                      </th>
                      <th className=" px-5 py-2 first:pl-3 last:pr-3 bg-slate-100 first:rounded-l last:rounded-r last:pl-5 last:sticky last:right-0"  style={{border:"3px solid #fff"}}>
                        <div className="font-medium text-left"  style={{fontSize:"23px"}}>Paper</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm font-medium">
                    <tr>
                      <td className="border px-4 py-2">JEE Main</td>
                      <td className="border px-4 py-2">5th April,Sunday</td>
                      <td className="border  py-2"></td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">JEE Main</td>
                      <td className="border px-4 py-2">7th April,Tuesday</td>
                      <td className="border px-4 py-2"></td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">JEE Main</td>
                      <td className="border px-4 py-2">8th April,Wednesday</td>
                      <td className="border px-4 py-2">Paper 1 & Paper 2</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">JEE Main</td>
                      <td className="border px-4 py-2">9th April,Thursday</td>
                      <td className="border px-4 py-2"></td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">JEE Main</td>
                      <td className="border px-4 py-2">11th April,Saturday</td>
                      <td className="border px-4 py-2"></td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">KEAM</td>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">20th April,Tuesday</td>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">Paper 1</td>
                    </tr>
                    <tr>
                    <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">KEAM</td>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">21st April,Monday</td>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">Paper 2</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">NEET</td>
                      <td className="border px-4 py-2">3rd May,Sunday</td>
                      <td className="border px-4 py-2">NEET 2020</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">ICAR</td>
                      <td className="border px-4 py-2">1st june,Monday</td>
                      <td className="border px-4 py-2"></td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">IISER</td>
                      <td className="border px-4 py-2">31st May,Monday</td>
                      <td className="border px-4 py-2">IISER Aptitude Test</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">CUSAT CAT</td>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">18th April,Saturday</td>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">Paper 1</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">CUSAT CAT</td>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">19th April,Saturday</td>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">Paper 2</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">NATA</td>
                      <td className="border px-4 py-2">19th April,Saturday</td>
                      <td className="border px-4 py-2"></td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">VIT</td>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">13th to 19th April</td>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">VITEEE</td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2">BITSAT</td>
                      <td className="border px-4 py-2">16th to 25th May</td>
                      <td className="border px-4 py-2"></td>
                    </tr>
                    <tr>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">Amritha</td>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">23rd to 27th April</td>
                      <td className="border px-4 py-2 bg-white bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800">AEEE</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Switcher />
      <Footer />
    </>
  );
}
