import React from 'react'
import logo_white from '../assets/images/logo-white-removed-bg.png';
import app from '../assets/images/app.png';
import playstore from '../assets/images/playstore.png';
import { Link } from 'react-router-dom';
import { Mail, Phone } from 'react-feather';
import {AiOutlineShoppingCart, MdKeyboardArrowRight, FaRegEnvelope, BsPencil, FiDribbble, BiLogoBehance, FaLinkedin, LiaFacebookF, AiOutlineInstagram, AiOutlineTwitter, GoLocation, TfiEmail, MdEmail, FaYoutube, BsYoutube } from "../assets/icons/vander"
import PhoneCall from 'feather-icons-react/build/IconComponents/PhoneCall';

export default function Footer() {
    return (
        <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200 mt-24 border-t border-black">
            <div className="container">
                <div className="grid grid-cols-1">
                    <div className="relative py-16">
                        <div className="relative w-full">
                            {/* <div className="relative -top-50 bg-white dark:bg-slate-900 lg:px-8 px-6 py-10 rounded-xl shadow dark:shadow-gray-800 overflow-hidden">
                                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                                    <div className="md:text-start text-center z-1">
                                        <h3 className="text-[26px] font-semibold text-slate-900 dark:text-white">Subscribe to Newsletter!</h3>
                                        <p className="text-slate-400 max-w-xl mx-auto">Subscribe to get latest updates and information.</p>
                                    </div>

                                    <div className="subcribe-form z-1">
                                        <form className="relative max-w-lg md:ms-auto">
                                            <input type="email" id="subcribe" name="email" className="pt-4 pe-40 pb-4 ps-6 w-full h-[50px] outline-none text-slate-900 dark:text-white rounded-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800" placeholder="Enter your email :" />
                                            <button type="submit" className="btn absolute top-[2px] end-[3px] h-[46px] bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full">Subscribe</button>
                                        </form>
                                    </div>
                                </div>

                                <div className="absolute -top-5 -start-5">
                                    <FaRegEnvelope className="lg:text-[150px] text-7xl text-slate-900/5 dark:text-white/5 -rotate-45"/>
                                </div>

                                <div className="absolute -bottom-5 -end-5">
                                   <BsPencil className="lg:text-[150px] text-7xl text-slate-900/5 dark:text-white/5"/>
                                </div>
                            </div> */}

                            <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] ">
                                <div className="lg:col-span-4 md:col-span-12">
                                    <Link to="#" className="text-[22px] focus:outline-none">
                                        <img src={logo_white} width={80} height={80} alt="" />
                                    </Link>
                                    <p className="mt-6 text-white">KBS Academy is an education centre with a difference. It was started with an objective of providing excellent science education for Plus I and Plus II students of Palakkad.</p> 
                                    

                                </div>
                                <div className="lg:col-span-3 md:col-span-12">
                                    <h5 className="tracking-[1px] text-lg text-white font-bold">CONTACT INFO</h5>
                                    <ul className="list-none footer-list mt-6">
                                        <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex"  style={{color:'black'}}><GoLocation className="me-2 text-lg"/> Near Ayyappankavu Junction, </Link></li>
                                        <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex"  style={{color:'black'}}> Near Ayyappankavu Junction, Pirayiri Post, Palakkad,</Link></li>
                                        <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex"  style={{color:'black'}}>PIN:678004 Kerala, India</Link></li>
                                        <li className="mt-[10px]"><Link to="mailto:sreeju.821@gmail.com" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"  style={{color:'white'}}><MdEmail className="me-3 text-lg"/> sreeju.821@gmail.com</Link></li>
                                        <li className="mt-[10px]"><Link to="tel:+91 9995484636" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"  style={{color:'white'}}><PhoneCall className="me-2 text-lg"/> +91 9995484636</Link></li>
                                    </ul>
                                    <ul className="list-none md:text-start mt-5">
                                        <li className="inline space-x-1"><Link to= "https://www.facebook.com/kbspalakkad?mibextid=ZbWKwL"  className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 "><LiaFacebookF  style={{color:'white'}} className='text-base'/></Link></li>
                                        <li className="inline space-x-1"><Link to= "https://www.instagram.com/kbs_academy_palakkad/?igsh=YTlnMGs4Zm5tMjdu"  className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 "><AiOutlineInstagram  style={{color:'white'}} className='text-base'/></Link></li>
                                        <li className="inline space-x-1"><Link to= "https://www.youtube.com/@kbsacademy2972"  className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 "><BsYoutube  style={{color:'white'}} className='text-base'/></Link></li>
                                        <li className="inline space-x-1"><Link to= "https://www.instagram.com/kbs_the_student_mentor/?igsh=aTMxcWVxMW1ldTBq"  className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 "><AiOutlineInstagram  style={{color:'white'}} className='text-base'/></Link></li>
                                    </ul>

                                </div>

                                <div className="lg:col-span-2 md:col-span-4">
                                    <h5 className="tracking-[1px] text-lg text-white font-bold">INFORMATION FOR</h5>
                                    <ul className="list-none footer-list mt-6">
                                        <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center">Announcements</Link></li>
                                        <li className="mt-[10px]"><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"> Academics</Link></li>
                                        
                                    </ul>
                                </div>

                                <div className="lg:col-span-2 md:col-span-4" >
                                    <h5 className="tracking-[1px] text-lg text-gray-100 font-semibold"><span className=" bg-clip-text">OUR LOCATIONS</span></h5>
                                    <ul className="list-none footer-list mt-6">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d250836.41218916932!2d76.37322469633914!3d10.786866219520117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba86e084d00b6b5%3A0xf74e6bc864017d8e!2sKBS%20Academy%20Palakkad%20Town%20Campus!5e0!3m2!1sen!2sin!4v1710735428272!5m2!1sen!2sin" width="300" height="170" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </ul>
                                </div>

                                {/* <div className="lg:col-span-3 md:col-span-4" style={{marginLeft:'0px'}}>
                                <div className="lg:col-span-3 md:col-span-4">
                                    <h5 className="tracking-[1px] text-lg text-white font-bold">Legal</h5>
                                    <ul className="list-none footer-list mt-6">
                                        <li><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"> Privacy</Link></li>
                                        <li className="mt-[10px]"><Link to="#" className="text-[16px] text-white hover:text-white duration-500 ease-in-out inline-flex items-center"> Terms</Link></li>
                                    </ul>
                                </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-[30px] px-0 border-t border-black dark:border-gray-7001">
                <div className="container text-center">
                    <div className="grid md:grid-cols-2 items-center gap-6">
                        <div className="md:text-start text-center">
                            <p className="mb-0 text-white"  style={{color:'black'}}>© {(new Date().getFullYear())}{" "}  KBS Academy | A <Link to="https://skyniche.com/" target="_blank" data-modal-toggle="loginform" className="btn btn-link text-white hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center">skyniche</Link> production | Privacy Policy</p>
                        </div>

                        {/* <ul className="list-none md:text-end text-center">
                            <li className="inline space-x-1"><Link   className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><LiaFacebookF  style={{color:'black'}} className='text-base'/></Link></li>
                            <li className="inline space-x-1"><Link   className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><AiOutlineInstagram  style={{color:'black'}} className='text-base'/></Link></li>
                            <li className="inline space-x-1"><Link   className="btn btn-icon btn-sm border border-black rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><AiOutlineTwitter  style={{color:'black'}} className='text-base'/></Link></li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </footer>
    )
}
