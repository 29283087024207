import React from 'react'
import image from '../../../assets/images/items/2.jpg';
import single from '../../../assets/images/blog/single.jpg';
import image1 from '../../../assets/images/blog/05.jpg';
import Navbar from '../../../components/navbar'
import Footer from '../../../components/footer'
import Switcher from '../../../components/switcher';
import { Link, useParams } from 'react-router-dom';
import image7 from '../../../assets/images/kbs1.png';
import image8 from '../../../assets/images/kbs2.jpg';
import {LuClock, LuCalendarDays, MdOutlineArrowForward} from "../../../assets/icons/vander"
// import { blogData } from '../../data/data';

export default function BlogDetail() {
    const params = useParams();
    const id = params.id
    // const blog = blogData.find((blogs) => blogs.id === parseInt (id));
    return (
        <>
            <Navbar />
            {/* <nav className="custom-nav" style={{marginTop:"150px"}}>
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
            <section className="relative pt-32 md:pb-24 pb-16">
                <div className="container">
                    <div className="md:flex justify-center">
                        <div className="lg:w-3/5">
                            <h5 className="md:leading-snug leading-snug font-semibold"  style={{display:'flex',justifyContent:'center',fontSize:'28px'}}>Engineering & Medical Entrance Online Crash Course</h5>
                            <p className='md:leading-snug leading-snug mt-4' style={{display:'flex',justifyContent:'center'}}>For major Entrance Exams – NEET/JEE/KEAM/NATA</p>
                            <p className="text-slate-400 text-lg mt-4">The main exams has been postponed the students will be getting ample time this year. So for a serious student without repeating he or she will be able to crack NEET or JEE.</p>
                            <p className="text-slate-400 text-lg mt-4">The online classes are not pre recorded. The live classes are aired. students are having good time interacting with the faculty.</p>
                        </div>
                    </div>

                    <hr style={{marginTop:'40px',border:'1px solid #fab81c'}}/>

                    <div className="container mt-16">
                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
                            <div className="lg:col-span-5">
                                <img src={image8} className="rounded-md shadow dark:shadow-gray-700" 
                                style={{width:'auto',height:'auto'}}
                                // style={{width:'372px',height:'372px'}}
                                 alt="" />
                            </div>

                            <div className="lg:col-span-7 lg:ms-8 mb-6">
                                <h5 className="md:text-2xl text-xl font-semibold">{"Step 1"}</h5>

                                <p className="text-slate-400 mt-4">Fill the online application using the below link.</p>
                                <p className="text-slate-400 mt-4"style={{fontSize:"15px"}}>http://localhost/kbs_academy/apply-online/</p>
                                {/* <p className="text-slate-400 mt-4">Over the years it has helped hundreds of students to pass their examinations with flying colors. KBS is the only institution in the district providing value-based education and empowering students to pursue higher education with a mission and vision. In short, KBS is all set to ensure a glorious future for the young generation.</p>
                            <ul className="list-none text-slate-400 mt-3">
                              <li className="flex items-center mt-2">Easily accessible learning center in Palakkad</li>
                              <li className="flex items-center mt-2">Classes for Plus I & Plus II Science students</li>
                              <li className="flex items-center mt-2">Term-wise mark lists & attendance reports will be sent to the parents</li>
                           </ul> */}
                           <br />
                            <h5 className="md:text-2xl text-xl font-semibold">{"Step 2"}</h5>
                            <ul className="list-none text-slate-400 mt-3">
                              <li className="flex items-center mt-2">Do the payment either as NEFT/IMPS/ Google pay</li>
                              <li className="flex items-center mt-2">Details for NEFT</li>
                              <li className="flex items-center mt-2">Current Account</li>
                              <li className="flex items-center mt-2">KBS ACADEMY</li>
                              <li className="flex items-center mt-2">AC No: 50200028047823</li>
                              <li className="flex items-center mt-2">Bank Name :HDFC</li>
                              <li className="flex items-center mt-2">IFSC CODE: HDFC0004851</li>
                           </ul>
                           <hr style={{marginTop:'40px',border:'1px solid #fab81c'}}/>
                           <ul className="list-none text-slate-400 mt-3">
                              <li className="flex items-center mt-2">Google pay number</li>
                              <li className="flex items-center mt-2">9995484636</li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 p-6"style={{marginTop:'50px'}} >
                         <div className="lg:col-span-3 md:col-span-12" >
                              <h5 className="tracking-[1px] text-lg text-white font-bold">After steps 1&2 we will guide you how to download the desktop or mobile app so that your ward can be a part of online classes. Also user id and password for online examination portal will be given </h5>
                         </div>
                    </div>
                    <h5 className="md:text-2xl text-xl font-semibold" style={{marginTop:'50px',display:'flex',justifyContent:'center'}}>{"News Coverage by ACV on Online live Crash Course"}</h5>
                    <div className="md:flex justify-center mt-16">
                        <div className="lg:w-full">
                            <div className="relative">
                            <iframe
                             className='w-full'
                            //  className='iframe'
                              src="https://www.youtube.com/embed/gdGcvZXu1aQ?si=PIKqV55VbBBPKpLW" style={{display:'flex',justifyContent:'center',height:'500px'}} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <Switcher />
        </>
    )
}
