import React, { useState, useEffect } from 'react';
import single from '../../../assets/images/result2024.jpg';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Switcher from '../../../components/switcher';
import { ResultImages } from '../../../data/data';
import HomeKBS from '../../../assets/images/background-images2024.jpg';
import { IoMdArrowBack, IoMdArrowForward, IoMdPause, IoMdPlay } from 'react-icons/io';

export default function Result2024() {

  return (
    <>
      <Navbar />
      {/* <nav className="custom-nav" >
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
        <section className="relative table w-full py-366 object-cover" style={{ backgroundImage: `url(${HomeKBS})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
          <div className="container">
              <div className="grid grid-cols-1 text-center" style={{ backdropFilter: 'blur(8px)', borderRadius: '6px' }}>
                  <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{ color: '#fff' }}><span>Results-Year 2024</span></h4>
              </div>
          </div>
        </section>
      <div className="container lg:mt-24 mt-16">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {ResultImages.slice(0,7).map((item, index) => {
                return (
                    <div key={index} className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-500">
                        <img src={item.img2024} style={{height:'500px',width:'100%'}} alt="" />
                    </div>
                )
            })}
        </div>
    </div>

      <section className="relative mt-16 md:pb-24 pb-16">
        <div className="container">
          <div className="md:flex justify-center"></div>

          <div className="md:flex justify-center mt-6">
            <div className="lg:w-full">
              <div className="relative">
                <img
                  style={{ justifyContent: 'center' }}
                  src={single}
                  className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Switcher />
    </>
  );
}