import React, { useState } from 'react'
import Navbar from '../../../components/navbar'
import Footer from '../../../components/footer';
import Switcher from '../../../components/switcher';
import { Link } from 'react-router-dom';
import { data } from '../../../data/data';
import Image1 from '../../../assets/images/about.jpg'
import HomeKBS from '../../../assets/images/background-gallery.png';
import Gallery1 from '../../../assets/images/gallery1.jpg';
import Gallery2 from '../../../assets/images/gallery2.jpg';
import Gallery3 from '../../../assets/images/gallery3.jpg';
import Gallery4 from '../../../assets/images/gallery4.jpg';
import Gallery5 from '../../../assets/images/gallery5.jpg';
import Gallery6 from '../../../assets/images/gallery6.jpg';
import Gallery7 from '../../../assets/images/gallery7.jpg';
import Gallery8 from '../../../assets/images/gallery8.jpg';
import Gallery9 from '../../../assets/images/gallery9.jpg';
import Gallery10 from '../../../assets/images/gallery10.jpg';
import Gallery11 from '../../../assets/images/gallery11.jpg';
import Gallery12 from '../../../assets/images/gallery12.jpg';
import Gallery13 from '../../../assets/images/gallery13.jpg';
import Gallery14 from '../../../assets/images/gallery14.jpg';
import Gallery15 from '../../../assets/images/gallery15.jpg';
import Gallery16 from '../../../assets/images/gallery16.jpg';
import Gallery17 from '../../../assets/images/gallery17.jpg';
import Gallery18 from '../../../assets/images/gallery18.jpg';
import Gallery19 from '../../../assets/images/gallery19.jpg';
import Gallery20 from '../../../assets/images/gallery20.jpg';
import Gallery21 from '../../../assets/images/gallery21.jpg';
import Gallery22 from '../../../assets/images/gallery22.jpg';
import Gallery23 from '../../../assets/images/gallery23.jpg';
import Gallery24 from '../../../assets/images/gallery24.jpg';
import Gallery25 from '../../../assets/images/gallery25.jpg';
import Gallery26 from '../../../assets/images/gallery26.jpg';
import Gallery27 from '../../../assets/images/gallery27.jpg';
import Gallery28 from '../../../assets/images/gallery28.jpg';
import Gallery29 from '../../../assets/images/gallery29.jpg';
import Gallery30 from '../../../assets/images/gallery30.jpg';
import Gallery31 from '../../../assets/images/gallery31.jpg';
import Gallery32 from '../../../assets/images/gallery32.jpg';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight,PiBrowsersLight, MdOutlineSportsVolleyball, AiOutlinePieChart, LuMusic4, TbCameraPlus, PiRocketLaunchBold} from "../../../assets/icons/vander"


const images = [
    { id: 1, src: Gallery1, name: 'Image 1',category: 'Images', },
    { id: 2, src: Gallery2, name: 'Image 2',category: 'Images', },
    { id: 3, src: Gallery3, name: 'Image 3',category: 'Images', },
    { id: 4, src: Gallery4, name: 'Image 4',category: 'Images', },
    { id: 5, src: Gallery5, name: 'Image 5',category: 'Images', },
    { id: 6, src: Gallery6, name: 'Image 6',category: 'Images', },
    { id: 7, src: Gallery7, name: 'Image 7', category: 'Images', },
    { id: 8, src: Gallery8, name: 'Image 8', category: 'Images', },
    { id: 9, src: Gallery9, name: 'Image 9',category: 'Images', },
    { id: 10, src:Gallery10, name: 'Image 10', category: 'Images', },
    { id: 11, src: Gallery11, name: 'Image 11', category: 'Images', },
    { id: 12, src: Gallery12, name: 'Image 12', category: 'Images', },
    { id: 13, src: Gallery13, name: 'Image 13', category: 'Images', },
    { id: 14, src: Gallery14, name: 'Image 14', category: 'Images', },
    { id: 15, src: Gallery15, name: 'Image 15', category: 'Images', },
    { id: 16, src: Gallery16, name: 'Image 16', category: 'Images', },
    { id: 17, src: Gallery17, name: 'Image 17', category: 'Images', },
    { id: 18, src: Gallery18, name: 'Image 18', category: 'Images', },
    { id: 20, src: Gallery20, name: 'Image 20', category: 'Images', },
    { id: 21, src: Gallery21, name: 'Image 21', category: 'Images', },
    { id: 22, src: Gallery22, name: 'Image 22', category: 'Images', },
    { id: 23, src: Gallery23, name: 'Image 23', category: 'Images', },
    { id: 24, src: 'https://www.youtube.com/embed/C0DPdy98e4c', name: 'Image 11', category: 'Videos' },
    { id: 25, src: Gallery25, name: 'Image 25', category: 'Images', },
    { id: 26, src: Gallery26, name: 'Image 26', category: 'Images', },
    { id: 27, src: Gallery27, name: 'Image 27', category: 'Images', },
    { id: 28, src: Gallery28, name: 'Image 28', category: 'Images', },
    { id: 29, src: Gallery29, name: 'Image 29', category: 'Images', },
    { id: 30, src: Gallery30, name: 'Image 30', category: 'Images', },
    { id: 31, src: Gallery31, name: 'Image 31', category: 'Images', },
    { id: 32, src: Gallery32, name: 'Image 32', category: 'Images', },
];

export default function Gallery() {

    const [lightboxMedia, setLightboxMedia] = useState({ type: '', src: '' });
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);

    const showLightbox = (media) => {
        setLightboxMedia(media);
        setIsLightboxOpen(true);
        document.body.style.overflow = 'hidden';
    };
    

    const closeLightbox = () => {
        setIsLightboxOpen(false);
        document.body.style.overflow = 'auto';
    };

    const [selectedCategory, setSelectedCategory] = useState(null);

    const matchCategory = (category) => {
        setSelectedCategory(category);
    };

    const filteredData = selectedCategory
        ? images.filter((item) => item.category === selectedCategory)
        : images;
    return (
        <>
            <Navbar />
            {/* <nav className="custom-nav" style={{marginTop:"150px"}}>
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}

            <section className="relative table w-full py-366 bg-cover" style={{ backgroundImage: `url(${HomeKBS})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                <div className="grid grid-cols-1 text-center" style={{backdropFilter: 'blur(8px)',borderRadius:'6px',}}>
                    <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{color:'#fff'}}><span>KBS Gallery</span></h4>
                </div>

                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid grid-cols-1 items-center gap-[30px]">
                        <div className="filters-group-wrap text-center">
                            <div className="filters-group">
                                <ul className="mb-0 list-none container-filter-box filter-options">
                                    <li onClick={() => matchCategory(null)} className={`${selectedCategory === null ? 'active' : ''} inline-flex items-center font-medium text-base mx-1.5 mb-3 py-1 px-3 cursor-pointer relative text-slate-400 border border-gray-100 dark:border-gray-700 rounded-full transition duration-500 `} data-group="all"><PiBrowsersLight className='me-1'/>  All</li>
                                    <li onClick={() => matchCategory('Images')} className={`${selectedCategory === 'Images' ? 'active' : ''} inline-flex items-center font-medium text-base mx-1.5 mb-3 py-1 px-3 cursor-pointer relative text-slate-400 border border-gray-100 dark:border-gray-700 rounded-full transition duration-500`}><MdOutlineSportsVolleyball className="me-1"/> Images</li>
                                    <li onClick={() => matchCategory('Videos')} className={`${selectedCategory === 'Videos' ? 'active' : ''} inline-flex items-center font-medium text-base mx-1.5 mb-3 py-1 px-3 cursor-pointer relative text-slate-400 border border-gray-100 dark:border-gray-700 rounded-full transition duration-500`} data-group="art"><AiOutlinePieChart className="me-1"/> Videos</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-4 gap-[30px]">
                        {
                            filteredData.map((item, index) => (
                                <div key={index} className="picture-item shuffle-item shuffle-item--visible" data-groups='["games"]'>
                                    <div className="group relative overflow-hidden p-2 rounded-lg bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:shadow-md dark:shadow-md hover:dark:shadow-gray-700 transition-all duration-500 hover:-mt-2 h-fit">
                                        <div className="relative overflow-hidden">
                                            <div className="relative overflow-hidden rounded-lg">
                                                <img src={item.image} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />
                                            </div>

                                            <div className="absolute -bottom-20 group-hover:bottom-1/2 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-center transition-all duration-500">
                                                <Link to={`/item-detail/${item.id}`} className="btn btn-sm rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"><i className="mdi mdi-lightning-bolt"></i> Buy Now</Link>
                                            </div>

                                            <div className="absolute top-2 end-2 opacity-0 group-hover:opacity-100 transition-all duration-500">
                                                <Link to="/" className="btn btn-icon btn-sm rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"><i className="mdi mdi-plus"></i></Link>
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                            <div className="flex items-center">
                                                <img src={item.avatar} className="rounded-full h-8 w-8" alt="" />
                                                <Link to={`/creator-profile/${item.id}`} className="ms-2 text-[15px] font-medium text-slate-400 hover:text-violet-600">{item.subtext}</Link>
                                            </div>

                                            <div className="my-3">
                                                <Link to={`/item-detail/${item.id}`} className="font-semibold hover:text-violet-600">{item.title}</Link>
                                            </div>

                                            <div className="flex justify-between p-2 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700">
                                                <div>
                                                    <span className="text-[16px] font-medium text-slate-400 block">Price</span>
                                                    <span className="text-[16px] font-semibold block"><i className="mdi mdi-ethereum"></i> 3.5 ETH</span>
                                                </div>

                                                <div>
                                                    <span className="text-[16px] font-medium text-slate-400 block">Highest Bid</span>
                                                    <span className="text-[16px] font-semibold block"><i className="mdi mdi-ethereum"></i> 3.55 ETH</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div> */}
                </div>
            </section>
            <div className={`lightbox ${isLightboxOpen ? 'show' : ''}`}>
                <div className="lightbox-overlay">
                    <div className="lightbox-content">
                        {lightboxMedia.type === 'image' && (
                            <div className="preview-img">
                                <div className="img-container">
                                    <img src={lightboxMedia.src} alt="preview-img" className="lightbox-image" />
                                    <i className="close-icon uil uil-times" onClick={closeLightbox}></i>
                                </div>
                            </div>
                        )}
                        {lightboxMedia.type === 'video' && (
                            <div className="video-container iframe-youtube" style={{marginLeft:'35px'}}>
                                <iframe
                                    title="video"
                                    className='iframe-youtube'
                                    src={lightboxMedia.src}
                                    frameBorder="0"
                                    allowFullScreen = "true"
                                ></iframe>
                                <i className="close-icon uil uil-times" style={{marginLeft:'30px'}} onClick={closeLightbox}></i>
                            </div>
                        )}

                    </div>
                </div>
            </div>

            <section className="gallery" style={{ marginTop: '-60px' }}>
                <ul className="images">
                {filteredData.map((media, index) => (
                    <li className="img" key={index}>
                        {media.category === 'Videos' ? (
                            <button className="video" onClick={() => showLightbox({ type: 'video', src: media.src })}>
                                <div className="relative">
                                    <img src={Gallery24} alt={media.name} />
                                    <div className=' absolute inset-0 flex items-center justify-center text-black'>
                                        <i className="logos--youtube-icon" style={{fontSize:'35px',}}></i>
                                    </div>
                                </div>
                            </button>
                        ) : (
                            <img src={media.src} alt={media.name} onClick={() => showLightbox({ type: 'image', src: media.src })} />
                        )}
                    </li>
                ))}
                </ul>
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
