import React, { useState, useEffect } from 'react';
import { EventImages } from '../../../data/data';
import HomeKBS from '../../../assets/images/events.jpg';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Switcher from '../../../components/switcher';
import { IoMdArrowBack, IoMdArrowForward, IoMdPause, IoMdPlay } from '../../../assets/icons/vander';

export default function Events() {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    const images = EventImages.map(item => item.image);

    const togglePlay = () => {
        setIsPlaying(!isPlaying);
    };

    const nextSlide = () => {
        setPhotoIndex((photoIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setPhotoIndex((photoIndex + images.length - 1) % images.length);
    };

    const closeLightbox = () => {
        setIsOpen(false);
        setIsPlaying(false);
    };

    useEffect(() => {
        let intervalId;
        if (isPlaying) {
            intervalId = setInterval(() => {
                setPhotoIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 2000); 
        } else {
            clearInterval(intervalId);
        }
        return () => clearInterval(intervalId);
    }, [isPlaying, images.length]);

    return (
        <>
            <Navbar />
            {/* <nav className="custom-nav" style={{marginTop:"150px"}}>
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
            <section className="relative table w-full py-366 object-cover" style={{ backgroundImage: `url(${HomeKBS})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center" style={{ backdropFilter: 'blur(8px)', borderRadius: '6px' }}>
                        <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{ color: '#fff' }}><span>Events</span></h4>
                    </div>
                </div>
            </section>
            <div className="container lg:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">KBS Academy-A pioneer entrance coaching center at palakkad,Kerala Honouring Toppers of Year 2022 </h3>
                </div>

                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                    {EventImages.slice(0, 4).map((item, index) => {
                        return (
                            <div key={index} className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-500" onClick={() => { setIsOpen(true); setPhotoIndex(index); }}>
                                <img src={item.image} alt="" />
                            </div>
                        )
                    })}
                </div>
                {isOpen && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 mt-10 p-6">
                        <div className="relative max-w-full max-h-full">
                            <img src={images[photoIndex]} alt="" className="max-w-full imgHei" style={{borderRadius:'10px',}}  />
                            <div className="absolute top-0 right-0 p-4">
                                <i className="text-white cursor-pointer close-icon uil uil-times" onClick={closeLightbox} />
                            </div>
                            <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center p-4 bg-black bg-opacity-50" style={{marginTop:'-60px'}}>
                                {isPlaying ? (
                                        <IoMdPause className="text-white cursor-pointer play" onClick={togglePlay} />
                                ) : (
                                    <IoMdPlay className="text-white cursor-pointer play" onClick={togglePlay} />
                                )}
                                <IoMdArrowBack className="text-white cursor-pointer mx-4 play" onClick={prevSlide} />
                                <IoMdArrowForward className="text-white cursor-pointer mx-4 play" onClick={nextSlide} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
            <Switcher />
        </>
    )
}