import React from 'react'
import { Link } from 'react-router-dom';
import { Hexagon } from 'react-feather';
import {TbSitemap, LuLayers, TbCameraPlus, PiStudent, PiStudentDuotone, PiStudentThin, CgCollage, MdBookOnline, RiCollageLine} from '../assets/icons/vander'

export default function KBSCourseHome() {
    return (
        <div className="container md:mt-24 mt-16">
            {/* <div className="grid grid-cols-1 text-center">
                <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">KBS Academy is a web3 destination.</h3>

                <p className="text-slate-400 max-w-xl mx-auto">We are a huge marketplace dedicated to connecting great artists of all KBS Academy with their fans and unique token collectors!</p>
            </div> */}

            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-10 gap-[30px]">

                <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                    <div className="relative overflow-hidden text-transparent -m-3">
                        <Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"></Hexagon>
                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                            <PiStudentDuotone/>
                        </div>
                    </div>

                    <div className="mt-6">
                        <Link to="#" className="text-lg h5 font-semibold transition duration-500 ease-in-out hover:text-violet-600">Coaching</Link>
                        <p className="text-slate-400 transition duration-500 ease-in-out mt-3">Entrance Coaching for JEE,NEET,KEAM, IISER,ICAR etc</p>
                    </div>
                </div>
                <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                    <div className="relative overflow-hidden text-transparent -m-3">
                        <Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"></Hexagon>
                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                            <span class="guidance--study-room"></span>
                        </div>
                    </div>

                    <div className="mt-6">
                        <Link to="#" className="text-lg h5 font-semibold transition duration-500 ease-in-out hover:text-violet-600">Repeater</Link>
                        <p className="text-slate-400 transition duration-500 ease-in-out mt-3">“Repeater Batch” NEET,JEE,KEAM</p>
                    </div>
                </div>
                <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                    <div className="relative overflow-hidden text-transparent -m-3">
                        <Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"></Hexagon>
                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                            <span class="maki--college"></span>
                        </div>
                    </div>

                    <div className="mt-6">
                        <Link to="#" className="text-lg h5 font-semibold transition duration-500 ease-in-out hover:text-violet-600">Tuition</Link>
                        <p className="text-slate-400 transition duration-500 ease-in-out mt-3">Tuition for 9th,10th,11th & 12th</p>
                    </div>
                </div>
                <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                    <div className="relative overflow-hidden text-transparent -m-3">
                        <Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"></Hexagon>
                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                            <span class="oui--online"></span>
                        </div>
                    </div>

                    <div className="mt-6">
                        <Link to="#" className="text-lg h5 font-semibold transition duration-500 ease-in-out hover:text-violet-600">Online Classes</Link>
                        <p className="text-slate-400 transition duration-500 ease-in-out mt-3">Online Classes for Tuitions & Entrance</p>
                    </div>
                </div>

            </div>
        </div>
    )
}
