import React, { useState, useEffect } from 'react';
import single from '../../../assets/images/single2.jpeg';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Switcher from '../../../components/switcher';
import { ResultImages } from '../../../data/data';
import HomeKBS from '../../../assets/images/success2.avif';
import { IoMdArrowBack, IoMdArrowForward, IoMdPause, IoMdPlay } from 'react-icons/io';

export default function Result2021() {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    const images = ResultImages.map(item => item.img2021);

    const togglePlay = () => {
        setIsPlaying(!isPlaying);
    };

    const nextSlide = () => {
        setPhotoIndex((photoIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setPhotoIndex((photoIndex + images.length - 1) % images.length);
    };

    const closeLightbox = () => {
        setIsOpen(false);
        setIsPlaying(false);
    };

    useEffect(() => {
        let intervalId;
        if (isPlaying) {
            intervalId = setInterval(() => {
                setPhotoIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 2000); 
        } else {
            clearInterval(intervalId);
        }
        return () => clearInterval(intervalId);
    }, [isPlaying, images.length]);

  return (
    <>
      <Navbar />
      {/* <nav className="custom-nav" >
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
        <section className="relative table w-full py-366 object-cover" style={{ backgroundImage: `url(${HomeKBS})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
          <div className="container">
              <div className="grid grid-cols-1 text-center" style={{ backdropFilter: 'blur(8px)', borderRadius: '6px' }}>
                  <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{ color: '#fff' }}><span>Results-Year 2021</span></h4>
              </div>
          </div>
        </section>
      <div className="container lg:mt-24 mt-24">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {ResultImages.map((item, index) => {
                return (
                    <div key={index} className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-500" onClick={() => { setIsOpen(true); setPhotoIndex(index); }}>
                        <img src={item.img2021} alt="" />
                    </div>
                )
            })}
        </div>
        {isOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 mt-10 p-6">
                <div className="relative max-w-full max-h-full">
                    <img src={images[photoIndex]} alt="" className="max-w-full imgHei" style={{borderRadius:'10px',}}  />
                    <div className="absolute top-0 right-0 p-4">
                        <i className="text-white cursor-pointer close-icon uil uil-times" onClick={closeLightbox} />
                    </div>
                    <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center p-4 bg-black bg-opacity-50" style={{marginTop:'-60px'}}>
                        {isPlaying ? (
                                <IoMdPause className="text-white cursor-pointer play" onClick={togglePlay} />
                        ) : (
                            <IoMdPlay className="text-white cursor-pointer play" onClick={togglePlay} />
                        )}
                        <IoMdArrowBack className="text-white cursor-pointer mx-4 play" onClick={prevSlide} />
                        <IoMdArrowForward className="text-white cursor-pointer mx-4 play" onClick={nextSlide} />
                    </div>
                </div>
            </div>
        )}
    </div>

      <section className="relative mt-16 md:pb-24 pb-16">
        <div className="container">
          <div className="md:flex justify-center"></div>

          <div className="md:flex justify-center mt-6">
            <div className="lg:w-full">
              <div className="relative">
                <img
                  style={{ justifyContent: 'center' }}
                  src={single}
                  className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Switcher />
    </>
  );
}