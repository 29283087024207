import React, { useEffect,useState } from 'react'
import Navbar from '../../components/navbar'
import Select from 'react-select'
import image from '../../assets/images/result-1.jpg';
import image1 from '../../assets/images/result-2.jpg';
import image2 from '../../assets/images/result-3.jpg';
import image3 from '../../assets/images/result-4.jpeg';
import image4 from '../../assets/images/result-5.jpeg';
import image5 from '../../assets/images/result-6.jpeg';
import image6 from '../../assets/images/result-7.jpg';
import image7 from '../../assets/images/result-8.jpg';
import image8 from '../../assets/images/result-9.jpg';
import image9 from '../../assets/images/result-10.jpg';
import image10 from '../../assets/images/result-11.jpg';
import image11 from '../../assets/images/image11 .jpg';
import image12 from '../../assets/images/image12 .jpg';
import image13 from '../../assets/images/image13 .jpg';
import image14 from '../../assets/images/image14.jpg';
import image15 from '../../assets/images/Result-32.jpg';
import image16 from '../../assets/images/Result-33.jpg';
import image17 from '../../assets/images/new-17.jpg';
import logo_white from '../../assets/images/logo-white.png';
import HomeKBS from '../../../src/assets/images/kbs-home.jpg';
import Footers from '../../../src/assets/images/kbs-footer.jpg';
import CollectionTwo from '../../components/collection-two';
import CreatorTwo from '../../components/creator-two';
import Footer from '../../components/footer';
import Feature from '../../components/feature';
import AuctionsTwo from '../../components/auctions-two';
import { Link } from 'react-router-dom';
import Switcher from '../../components/switcher';
import {LuClock, LuSearch,AiOutlineAppstore,AiOutlineStar} from "../../assets/icons/vander"
import KBSCourseHome from '../../components/kbs-course-home';
import KBSAnnouncementsHome from '../../components/kbs-announcements-home';
import TutuionEntrance from '../../components/tution-entrance';
import Entrance from '../../components/entrance';
import Tuition from '../../components/tution';
import Vacation from '../../components/vacation';
import WhyKBS from '../why-kbs';
import { entrance } from '../../data/data';
import Menu from './animation-switcher/animation-switcher';
import CustomMenu from './animation-switcher/animation-switcher';

const options = [
    { value: 'Art', label: 'Art' },
    { value: 'Domain', label: 'Domain Names' },
    { value: 'Music', label: 'Music' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Trading', label: 'Trading Cards' },
    { value: 'Virtual', label: 'Virtual World' },
];

const buyOptions = [
    { value: 'now', label: 'Buy Now' },
    { value: 'auctions', label: 'Auctions' },
    { value: 'offers', label: 'Offers' }
]
const rateOptions = [
    { value: 'top', label: 'Top Rated' },
    { value: 'low', label: 'Lower Rated' }
]

export default function KBSHome() {
    const [activeIndex, setIndex] = useState(0);

    return (
        <>
            <Navbar />
            <nav className="custom-nav">
              <ul>
                <li className="facebook">
                  <a href="https://www.facebook.com/kbspalakkad?mibextid=ZbWKwL">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="https://www.instagram.com/kbs_academy_palakkad/?igsh=YTlnMGs4Zm5tMjdu">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="https://www.youtube.com/@kbsacademy2972">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="https://www.instagram.com/kbs_the_student_mentor/?igsh=aTMxcWVxMW1ldTBq">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
              </ul>
            </nav>
            {/* <!-- Start --> */}
            <section className="relative table w-full py-36 bg-cover" style={{ backgroundImage: `url(${HomeKBS})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                <div className="grid grid-cols-1 text-center" style={{backdropFilter: 'blur(8px)',borderRadius:'6px'}}>
                    <img src={logo_white} width={120} height={120} alt="Image Description" class="mx-auto mt-6"/>
                    <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{color:'#fff'}}><span>Excel Academically with KBS </span>Your Premier Tuitions & Entrance Coaching Center</h4>
                    {/* <p className="max-w-xl mx-auto" style={{color:'#000'}}>Explore a marketplace where AI models are uniquely represented as NFTs for intuitive trading and creation.</p> */}
                    <div className="mb-3">
                        <Link to="https://docs.google.com/forms/d/e/1FAIpQLSc-5YtQHmJiCb_krw_WCMLirRqtDJ6jPxd7nI7KY7wYrMpPWw/viewform?usp=sf_link" target="_blank" className="btn text-white rounded-full me-2 mt-2" style={{cursor:'pointer',background:'#c82325',color:'#fff',border:'1px solid #c82325'}}>Apply Now</Link>
                    </div>
                </div>

                </div>
            </section>
            <section className="relative pt-24 overflow-hidden" style={{marginTop:'50px'}}>
                <div className="container-fluid relative">
                    <div className="grid grid-cols-1">
                        <div className="slider relative overflow-hidden m-auto mb-4 before:content-[''] before:absolute before:top-0 before:start-0 before:z-2 after:content-[''] after:absolute after:top-0 after:end-0 after:z-2">
                            <div className="slide-track flex items-center">
                                
                                <div className="slide md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image17} style={{height:'360px',width:'360px',objectFit:'cover'}} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />
                                        
                                    </div>
                                </div>
                                <div className="slide md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image16} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />
                                        
                                    </div>
                                </div>
                                <div className="slide md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image15} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />

                                        
                                    </div>
                                </div>
                                <div className="slide md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image11} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />

                                        
                                    </div>
                                </div>
                                <div className="slide h-auto md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image12} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />

                                        
                                    </div>
                                </div>
                                <div className="slide h-auto md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image13} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />

                                        
                                    </div>
                                </div>
                                <div className="slide h-auto md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image14} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />

                                        
                                    </div>
                                </div>
                                <div className="slide h-auto md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image1} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />

                                        
                                    </div>
                                </div>
                                <div className="slide md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />
                                    </div>
                                </div>

                                <div className="slide h-auto md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image2} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />

                                        
                                    </div>
                                </div>

                                <div className="slide h-auto md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image3} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />

                                        
                                    </div>
                                </div>

                                <div className="slide h-auto md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image4} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />

                                        
                                    </div>
                                </div>

                                <div className="slide h-auto md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image5} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />

                                        
                                    </div>
                                </div>
                                <div className="slide h-auto md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image6} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />

                                        
                                    </div>
                                </div>

                                <div className="slide h-auto md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image7} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />

                                        
                                    </div>
                                </div>

                                <div className="slide h-auto md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image8} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />

                                        
                                    </div>
                                </div>
                                <div className="slide h-auto md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image9} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />

                                        
                                    </div>
                                </div>

                                <div className="slide h-auto md:w-[360px] w-72 mx-2">
                                    <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                                        <img src={image10} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
               
            
            <section className="relative md:pb-24 pb-16" style={{marginTop:'-10px'}}>
                <KBSCourseHome />
                <div className='mt-24'>
                    <KBSAnnouncementsHome title="Announcements" />
                </div>
                
                <div className="container mt-24" style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="grid grid-cols-1 text-center" style={{ backdropFilter: 'blur(8px)', borderRadius: '6px' }}>
                        <ul className="md:w-fit w-full flex-wrap justify-center text-center p-3 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md" id="myTab" data-tabs-toggle="#StarterContent" role="tablist">
                            <li role="presentation" className="md:inline-block block md:w-fit w-full">
                                <button className={`px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${activeIndex === 0 ? 'text-white bg-violet-600' : ''}`} id="thursday-tab" data-tabs-target="#thursday" type="button" role="tab" aria-controls="thursday" aria-selected={activeIndex === 0 ? 'true' : 'false'} onClick={() => setIndex(0)}>Entrance Only</button>
                            </li>
                            <li role="presentation" className="md:inline-block block md:w-fit w-full">
                                <button className={`px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${activeIndex === 1 ? 'text-white bg-violet-600' : ''}`} id="wednesday-tab" data-tabs-target="#wednesday" type="button" role="tab" aria-controls="wednesday" aria-selected={activeIndex === 1 ? 'true' : 'false'} onClick={() => setIndex(1)}>Tuition + Entrance</button>
                            </li>
                            <li role="presentation" className="md:inline-block block md:w-fit w-full">
                                <button className={`px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${activeIndex === 2 ? 'text-white bg-violet-600' : ''}`} id="friday-tab" data-tabs-target="#friday" type="button" role="tab" aria-controls="friday" aria-selected={activeIndex === 2 ? 'true' : 'false'} onClick={() => setIndex(2)}>Tuition Only</button>
                            </li>
                            <li role="presentation" className="md:inline-block block md:w-fit w-full">
                                <button className={`px-6 py-2 font-semibold rounded-md w-full transition-all duration-500 ease-in-out ${activeIndex === 3 ? 'text-white bg-violet-600' : ''}`} id="saturday-tab" data-tabs-target="#saturday" type="button" role="tab" aria-controls="saturday" aria-selected={activeIndex === 3 ? 'true' : 'false'} onClick={() => setIndex(3)}>Foundation / Skill Development / Vacation Courses</button>
                            </li>
                        </ul>
                    </div>  
                </div>
                 
                {activeIndex === 1 && <TutuionEntrance title="Tuition + Entrance" />}
                {activeIndex === 0 && <Entrance title="Entrance Only" />}
                {activeIndex === 2 && <Tuition title="Tuition Only" />}
                {activeIndex === 3 && <Vacation title="Foundation / Skill Development / Vacation Courses" />}
                <div style={{marginTop:'-120px',}}>
                    <WhyKBS />
                </div>
                <div style={{marginTop:'-140px'}}>
                <section className="relative"  style={{marginTop:'100px'}}>
                <div className="container">
                    <h2 to="#" className="font-semibold transition duration-500" style={{fontSize:'30px',marginBottom:'35px',}}>NEET</h2>
                        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-[30px]">
                            {
                                entrance.map((ele, index) => (
                                    <div key={index} className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-500">
                                        <div style={{maxWidth:'100%',height:'150px',backgroundColor:'#1e293b'}}>
                                            <div className="relative p-6" style={{display:'flex',alignItems:'center',justifyContent:'center',textAlign:'center'}}>
                                                <Link to={`#`} className="title text-lg font-medium hover:text-violet-600 duration-500 ease-in-out mt-8">{ele.title1}</Link>                             
                                            </div>
                                        </div>
                                
                                        <div className="relative p-6">                               
                                            <div className="">
                                                <div className="flex mb-4" style={{display:'flex',flexDirection:'column'}}>
                                                    <span className="text-slate-400 text-[16px] inline-flex mb-4">{ele.q1}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-4" style={{display:'flex',justifyContent:'center',bottom:0}}>
                                        <Link to="#" className="btn bg-transparent hover:bg-violet-600 border-violet-600 text-violet-600 hover:text-white rounded-full ms-2" style={{cursor:'pointer',}}>Download</Link>
                                    </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
                </div>
                <section className="relative lg:pt-8 pt-[74px] overflow-hidden" style={{marginTop:'70px'}}>
                    <div className="container-fluid lg:px-10 md:px-3 relative overflow-hidden" >
                        <span className="absolute blur-[200px] w-[600px] h-[600px] rounded-full top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 bg-gradient-to-tl from-red-600/40 to-violet-600/40 dark:from-red-600/60 dark:to-violet-600/60"></span>
                        <div className="lg:py-24 py-[74px] md:rounded-lg shadow dark:shadow-gray-800 bg-violet-700/10 dark:bg-violet-600/20" style={{backgroundImage: `url(${Footers})`,objectFit:'fill'}}>
                            <div className="container" >
                                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]" style={{display:'flex',justifyContent:'center'}}>
                                    <div className="md:col-span-7" style={{display:'flex',justifyContent:'center'}}>
                                        <div className="md:me-6" style={{backdropFilter: 'blur(10px)',borderRadius:'6px',padding:'10px'}}>
                                            <h4 className="font-bold lg:leading-snug leading-snug text-5xl lg:text-4xl mb-4 underline">Our Results</h4>
                                            <div class="flex flex-wrap">
                                                <div class="md:w-1/3 lg:pr-8 mb-8 md:mb-0">
                                                    <div class="p-4  rounded-md">
                                                        <h4 class="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4">80%</h4>
                                                        <p class="text-lg max-w-xl">NEET 2020 Selections <br/> Medical Entrance</p>
                                                    </div>
                                                </div>
                                                <div class="md:w-1/3 lg:pr-8 mb-8 md:mb-0">
                                                    <div class="p-4  rounded-md">
                                                        <h4 class="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4">78%</h4>
                                                        <p class="text-lg max-w-xl">IIT-JEE 2020 Selections <br/> Engineering</p>
                                                    </div>
                                                </div>
                                                <div class="md:w-1/3 lg:pr-8 mb-8 md:mb-0">
                                                    <div class="p-4 rounded-md">
                                                        <h4 class="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4">95</h4>
                                                        <p class="text-lg max-w-xl">KEAM 2020 Selections <br/> Engineering</p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <AuctionsTwo title="Live Auctions" description="We are a huge marketplace dedicated to connecting great artists of all KBS Academy with their fans and unique token collectors!"/> */}
                
            </section>
        
            <Footer />
            <Switcher />
        </>
    )
}
