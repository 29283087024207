import React from 'react';
import single from '../../../assets/images/single.png';
import single1 from '../../../assets/images/single1.jpg';
import HomeKBS from '../../../assets/images/sucess1.avif';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Switcher from '../../../components/switcher';

export default function Result2020() {

  return (
    <>
      <Navbar />
      {/* <nav className="custom-nav" >
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
      <section className="relative table w-full py-366 object-cover" style={{ backgroundImage: `url(${HomeKBS})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
        <div className="container">
            <div className="grid grid-cols-1 text-center" style={{ backdropFilter: 'blur(8px)', borderRadius: '6px' }}>
                <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{ color: '#fff' }}><span>Results-Year 2020</span></h4>
            </div>
        </div>
      </section>
      <section className="relative pt-32 md:pb-24 pb-16">
        <div className="container">
          <div className="md:flex justify-center"></div>

          <div className="md:flex justify-center mt-6">
            <div className="lg:w-full">
              <div className="relative">
                <img
                  style={{ justifyContent: 'center' }}
                  src={single}
                  className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="md:flex justify-center"></div>

          <div className="md:flex justify-center mt-16">
            <div className="lg:w-full">
              <div className="relative">
                <img
                  style={{ justifyContent: 'center' }}
                  src={single1}
                  className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Switcher />
    </>
  );
}